import React from 'react'
import { PaymentPageIcons } from 'Rentlio/components/UI/PaymentPageIcons/style'
import ApplePayIcon from 'Rentlio/components/Icons/applePay'
import PayPalIcon from 'Rentlio/components/Icons/payPal'
import ClickToPayIcon from 'Rentlio/components/Icons/clickToPay'
import GooglePayIcon from 'Rentlio/components/Icons/googlePay'
import Amex from 'Rentlio/components/Icons/amex'
import { BE_SAFERPAY } from 'Rentlio/utils/paymentTypesEnum'

const hasAmexEnabled = cardTypes => {
  return cardTypes?.split(',').some(type => type === AMEX)
}

const getOptionId = (...options) =>
  [BE_SAFERPAY, ...options.map(option => SaferpayPaymentPageEnum[option].value)].join('')

export const GOOGLE_PAY = 'googlepay'
export const APPLE_PAY = 'applepay'
export const CLICK_TO_PAY = 'clicktopay'
export const PAY_PAL = 'paypal'
export const AMEX = 'amex'

export const SaferpayPaymentPageEnum = {
  [GOOGLE_PAY]: {
    value: GOOGLE_PAY,
    label: 'google pay',
    id: GOOGLE_PAY,
    icon: <GooglePayIcon />
  },
  [APPLE_PAY]: {
    value: APPLE_PAY,
    label: 'apple pay',
    id: APPLE_PAY,
    icon: <ApplePayIcon />
  },
  [CLICK_TO_PAY]: {
    value: CLICK_TO_PAY,
    label: 'click to pay',
    id: CLICK_TO_PAY,
    icon: <ClickToPayIcon />
  },
  [PAY_PAL]: {
    value: PAY_PAL,
    label: 'pay pal',
    id: PAY_PAL,
    icon: <PayPalIcon />
  },
  [AMEX]: {
    value: AMEX,
    label: 'amex',
    id: AMEX,
    icon: <Amex />
  }
}

export const getOtherPaymentOptions = saferpayMethod => {
  const otherOptions = []

  const hasAmex = hasAmexEnabled(saferpayMethod?.settings?.selectedCardTypes)

  if (
    (saferpayMethod?.settings?.selectedWalletTypes &&
      saferpayMethod?.settings?.selectedWalletTypes?.split(',')?.length > 0) ||
    hasAmex
  ) {
    let wallets = saferpayMethod?.settings?.selectedWalletTypes?.split(',').filter(Boolean) || []

    // AMEX does not support forcing 3DS through the API method we use, so 3DS would not
    // necessarily be triggered for guests. As a result, the tokenized card cannot be charged by
    // the user because the MIT transaction would be declined. The workaround is to use
    // their Payment Page or Transaction Interface with a certain amount to force 3DS.
    // It was easier for us to simply add the Payment Page here.
    if (hasAmex) {
      wallets.push(AMEX)
    }

    if (wallets.includes(GOOGLE_PAY) && wallets.includes(APPLE_PAY)) {
      // remove them from array to avoid duplication
      wallets = wallets.filter(item => item !== GOOGLE_PAY && item !== APPLE_PAY)

      const googlePay = SaferpayPaymentPageEnum[GOOGLE_PAY]
      const applePay = SaferpayPaymentPageEnum[APPLE_PAY]

      otherOptions.push({
        bookingEngineId: saferpayMethod.bookingEngineId,
        paymentPageOption: `${GOOGLE_PAY},${APPLE_PAY}`,
        component: (
          <PaymentPageIcons>
            {googlePay.icon}
            {applePay.icon}
          </PaymentPageIcons>
        ),
        id: getOptionId(GOOGLE_PAY, APPLE_PAY)
      })
    }

    wallets.forEach(item => {
      const paymentPageOption = SaferpayPaymentPageEnum[item]

      if (!paymentPageOption) {
        return
      }

      otherOptions.push({
        bookingEngineId: saferpayMethod.bookingEngineId,
        paymentPageOption: item,
        id: getOptionId(item),
        component: <PaymentPageIcons>{paymentPageOption.icon}</PaymentPageIcons>
      })
    })
  }

  return otherOptions
}

export const getPaymentPageWalletsArray = selectedPaymentMethod => {
  switch (selectedPaymentMethod) {
    case getOptionId(GOOGLE_PAY, APPLE_PAY):
      return ['GOOGLEPAY', 'APPLEPAY']
    case getOptionId(GOOGLE_PAY):
      return ['GOOGLEPAY']
    case getOptionId(APPLE_PAY):
      return ['APPLEPAY']
    case getOptionId(CLICK_TO_PAY):
      return ['CLICKTOPAY']
    default:
      return []
  }
}

export const getPaymentPageMethodsArray = selectedPaymentMethod => {
  switch (selectedPaymentMethod) {
    case getOptionId(PAY_PAL):
      return ['PAYPAL']
    case getOptionId(AMEX):
      return ['AMEX']
    default:
      return []
  }
}
