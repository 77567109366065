import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import moment from 'moment'
import CancellationPolicy from '../CancellationPolicyFull'
import {
  CancellationPolicyWrapper,
  Label,
  ReceiptItemRowWrapper,
  Value,
  Name,
  CheckInOutWrapper,
  CheckInOutContainer
} from './style'
import { paymentTypes } from 'Rentlio/utils/services'
import Times from 'Rentlio/components/Icons/times'
import { formatPrice } from 'Rentlio/components/utils/Price/price'
import { LanguageContext } from 'Rentlio/context/LanguageContext'
import { CurrenciesContext } from 'Rentlio/context/CurrenciesContext'

class ReceiptItemRow extends React.PureComponent {
  static contextType = LanguageContext

  isPerNight = () =>
    !this.props.item.isMandatory &&
    (this.props.item.paymentType === paymentTypes.PER_NIGHT ||
      this.props.item.paymentType === paymentTypes.PER_PERSON_PER_NIGHT)

  getCheckInOutText = (hoursFrom, hoursTo, arrivalDepartureDate) => {
    const { translate } = this.context

    return `${arrivalDepartureDate.utc().format('dddd')},  ${arrivalDepartureDate.utc().format('LL')} (${
      hoursFrom ? translate('From') + ' ' : ''
    }${hoursFrom || translate('WholeDay')}${hoursTo ? ' ' + translate('Until') + ' ' + hoursTo + ')' : ')'}`
  }

  render() {
    const { item, nights, isAccommodation, from, to, selectedPaymentMethod, createdAt } = this.props
    const { translate } = this.context

    return (
      <CurrenciesContext.Consumer>
        {({ currencyConversion }) => {
          const { value, currency } = currencyConversion(item.price, item.currency)
          const { value: servicesCost } = currencyConversion(item.servicesCost, item.currency)
          const startDate = moment.unix(item.checkInDate ? item.checkInDate : from)
          const endDate = moment.unix(item.checkOutDate ? item.checkOutDate : to)

          return (
            <ReceiptItemRowWrapper>
              <Label>
                <span>{item.quantity}</span>
                <Times />
                <Name>
                  {item.name}
                  {isAccommodation && ` (${item.ratePlanName})`}
                </Name>
                {this.isPerNight() && (
                  <Fragment>
                    <Times />
                    {nights} {translate('nights')}
                  </Fragment>
                )}
              </Label>
              <Value>
                {item.isIncluded && translate('Included')}
                {!item.isIncluded && `${formatPrice(value)} ${currency}`}
              </Value>
              {isAccommodation && (
                <>
                  <CheckInOutContainer>
                    <CheckInOutWrapper>
                      <Label>{translate('Check in')}</Label>
                      <Label>{this.getCheckInOutText(item.checkInBegin, item.checkInEnd, startDate)}</Label>
                    </CheckInOutWrapper>
                    <CheckInOutWrapper>
                      <Label>{translate('Check out')}</Label>
                      <Label>{this.getCheckInOutText(item.checkOutBegin, item.checkOutEnd, endDate)}</Label>
                    </CheckInOutWrapper>
                  </CheckInOutContainer>

                  <CancellationPolicyWrapper>
                    <CancellationPolicy
                      policy={item.cancellationPolicy}
                      totalRatePrice={value}
                      currency={currency}
                      paymentMethod={selectedPaymentMethod}
                      servicesCost={servicesCost}
                      arrivalDate={from}
                      createdAt={createdAt}
                    />
                  </CancellationPolicyWrapper>
                </>
              )}
            </ReceiptItemRowWrapper>
          )
        }}
      </CurrenciesContext.Consumer>
    )
  }
}

ReceiptItemRow.defaultProps = {
  isAccommodation: false
}

ReceiptItemRow.propTypes = {
  item: PropTypes.object,
  nights: PropTypes.number,
  isAccommodation: PropTypes.bool,
  from: PropTypes.number,
  to: PropTypes.number,
  selectedPaymentMethod: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  createdAt: PropTypes.number
}

export default ReceiptItemRow
