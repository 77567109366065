import React from 'react'
import PropTypes from 'prop-types'

const PayPalIcon = ({ width, height }) => (
  <svg width={width} height={height} viewBox='0 0 76 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_1855_2851)'>
      <path
        d='M28.3233 4.08984H24.1317C23.8448 4.08984 23.6009 4.2959 23.5561 4.5759L21.8608 15.2044C21.8271 15.4141 21.9914 15.6032 22.2065 15.6032H24.2077C24.4945 15.6032 24.7384 15.3971 24.7832 15.1165L25.2404 12.2498C25.2845 11.9692 25.5291 11.7632 25.8153 11.7632H27.1422C29.9034 11.7632 31.4969 10.442 31.9131 7.82378C32.1006 6.67833 31.921 5.77833 31.3786 5.14803C30.7829 4.4559 29.7262 4.08984 28.3233 4.08984ZM28.8069 7.97166C28.5777 9.45893 27.4285 9.45893 26.3173 9.45893H25.6847L26.1285 6.68136C26.1548 6.51348 26.3019 6.38984 26.4736 6.38984H26.7635C27.5204 6.38984 28.2344 6.38984 28.6034 6.81651C28.8234 7.07106 28.8908 7.44924 28.8069 7.97166Z'
        fill='#253B80'
      />
      <path
        d='M40.8527 7.92251H38.8455C38.6745 7.92251 38.5268 8.04614 38.5004 8.21402L38.4115 8.76917L38.2712 8.56796C37.8366 7.94432 36.8676 7.73584 35.9005 7.73584C33.6824 7.73584 31.7879 9.39705 31.4189 11.7274C31.2271 12.8898 31.4998 14.0013 32.1667 14.7764C32.7784 15.4892 33.6536 15.7861 34.6949 15.7861C36.4821 15.7861 37.4732 14.6498 37.4732 14.6498L37.3837 15.2013C37.35 15.4122 37.5143 15.6013 37.7282 15.6013H39.5362C39.8237 15.6013 40.0664 15.3952 40.1117 15.1146L41.1966 8.32129C41.2309 8.1122 41.0673 7.92251 40.8527 7.92251ZM38.0548 11.7855C37.8612 12.9195 36.951 13.6807 35.7902 13.6807C35.2073 13.6807 34.7415 13.4958 34.4424 13.1455C34.1457 12.7977 34.033 12.3025 34.1274 11.751C34.3082 10.6267 35.2336 9.84069 36.3767 9.84069C36.9467 9.84069 37.4101 10.028 37.7153 10.3813C38.0211 10.7383 38.1425 11.2364 38.0548 11.7855Z'
        fill='#253B80'
      />
      <path
        d='M51.5425 7.92285H49.5254C49.333 7.92285 49.1522 8.0174 49.0431 8.17558L46.2611 12.2277L45.0819 8.33376C45.0077 8.09012 44.7803 7.92285 44.5229 7.92285H42.5408C42.2999 7.92285 42.1326 8.15558 42.2092 8.37982L44.431 14.8271L42.3422 17.7429C42.1779 17.9725 42.3434 18.2883 42.6272 18.2883H44.6418C44.833 18.2883 45.012 18.1962 45.1205 18.041L51.8293 8.46528C51.9899 8.23619 51.825 7.92285 51.5425 7.92285Z'
        fill='#253B80'
      />
      <path
        d='M58.2208 4.08984H54.0285C53.7423 4.08984 53.4983 4.2959 53.4536 4.5759L51.7583 15.2044C51.7246 15.4141 51.8889 15.6032 52.1028 15.6032H54.254C54.4539 15.6032 54.6249 15.4589 54.6561 15.2626L55.1372 12.2498C55.1814 11.9692 55.4259 11.7632 55.7121 11.7632H57.0385C59.8002 11.7632 61.3931 10.442 61.8099 7.82378C61.9981 6.67833 61.8173 5.77833 61.2749 5.14803C60.6797 4.4559 59.6237 4.08984 58.2208 4.08984ZM58.7043 7.97166C58.4757 9.45893 57.3265 9.45893 56.2147 9.45893H55.5828L56.0272 6.68136C56.0535 6.51348 56.1994 6.38984 56.3716 6.38984H56.6615C57.4179 6.38984 58.1325 6.38984 58.5015 6.81651C58.7215 7.07106 58.7883 7.44924 58.7043 7.97166Z'
        fill='#179BD7'
      />
      <path
        d='M70.75 7.92251H68.744C68.5718 7.92251 68.4253 8.04614 68.3995 8.21402L68.3107 8.76917L68.1697 8.56796C67.7351 7.94432 66.7668 7.73584 65.7996 7.73584C63.5815 7.73584 61.6876 9.39705 61.3187 11.7274C61.1274 12.8898 61.399 14.0013 62.0658 14.7764C62.6787 15.4892 63.5527 15.7861 64.594 15.7861C66.3812 15.7861 67.3723 14.6498 67.3723 14.6498L67.2828 15.2013C67.2491 15.4122 67.4134 15.6013 67.6285 15.6013H69.436C69.7222 15.6013 69.9661 15.3952 70.0109 15.1146L71.0963 8.32129C71.1294 8.1122 70.9651 7.92251 70.75 7.92251ZM67.9521 11.7855C67.7597 12.9195 66.8483 13.6807 65.6874 13.6807C65.1058 13.6807 64.6388 13.4958 64.3397 13.1455C64.043 12.7977 63.9315 12.3025 64.0246 11.751C64.2067 10.6267 65.1309 9.84069 66.274 9.84069C66.844 9.84069 67.3073 10.028 67.6126 10.3813C67.9196 10.7383 68.041 11.2364 67.9521 11.7855Z'
        fill='#179BD7'
      />
      <path
        d='M73.1159 4.37952L71.3955 15.2026C71.3618 15.4123 71.5261 15.6013 71.74 15.6013H73.4696C73.757 15.6013 74.001 15.3953 74.0451 15.1147L75.7416 4.4868C75.7753 4.2771 75.6111 4.0874 75.3972 4.0874H73.4604C73.2894 4.08801 73.1423 4.21164 73.1159 4.37952Z'
        fill='#179BD7'
      />
      <path
        d='M4.45297 17.6676L4.77352 15.6543L4.05948 15.6379H0.649902L3.01939 0.781565C3.02674 0.736716 3.05064 0.694898 3.08558 0.665201C3.12052 0.635504 3.16526 0.619141 3.21184 0.619141H8.96087C10.8695 0.619141 12.1866 1.01187 12.8743 1.78702C13.1966 2.15066 13.402 2.53066 13.5013 2.94884C13.6054 3.38763 13.6073 3.91187 13.5055 4.55126L13.4982 4.59793V5.00763L13.8206 5.18823C14.0921 5.33066 14.3078 5.49369 14.4733 5.68035C14.7491 5.99126 14.9275 6.38641 15.0029 6.8549C15.0807 7.33672 15.055 7.91005 14.9275 8.55914C14.7804 9.30581 14.5426 9.95611 14.2214 10.4882C13.926 10.9785 13.5497 11.3852 13.1029 11.7004C12.6763 11.9997 12.1694 12.227 11.5964 12.3725C11.0411 12.5155 10.4079 12.5876 9.71351 12.5876H9.2661C8.94616 12.5876 8.63542 12.7016 8.39148 12.9058C8.14693 13.1143 7.98513 13.3991 7.93548 13.7107L7.90177 13.8919L7.33545 17.4404L7.30971 17.5707C7.30297 17.6119 7.29132 17.6325 7.27416 17.6464C7.25884 17.6591 7.23677 17.6676 7.21532 17.6676H4.45297Z'
        fill='#253B80'
      />
      <path
        d='M14.1263 4.646C14.1092 4.75448 14.0895 4.86539 14.0675 4.97933C13.3093 8.82842 10.7155 10.1581 7.40276 10.1581H5.71605C5.31093 10.1581 4.96954 10.449 4.90641 10.8442L4.04283 16.2599L3.79828 17.7951C3.75722 18.0545 3.95947 18.2884 4.22425 18.2884H7.21583C7.57009 18.2884 7.87102 18.0339 7.9268 17.6884L7.95622 17.5381L8.51947 14.0036L8.55563 13.8096C8.6108 13.463 8.91234 13.2084 9.2666 13.2084H9.71402C12.6124 13.2084 14.8814 12.0448 15.5446 8.67751C15.8216 7.27084 15.6782 6.0963 14.9452 5.27024C14.7233 5.02115 14.4481 4.81448 14.1263 4.646Z'
        fill='#179BD7'
      />
      <path
        d='M13.3331 4.33306C13.2172 4.29972 13.0977 4.26942 12.9751 4.24215C12.8519 4.21548 12.7257 4.19184 12.5957 4.17124C12.141 4.09851 11.6427 4.06396 11.1088 4.06396H6.60277C6.49183 4.06396 6.38641 4.08881 6.29202 4.13366C6.08425 4.23245 5.9298 4.427 5.89241 4.66518L4.93383 10.6688L4.90625 10.844C4.96938 10.4488 5.31077 10.1579 5.7159 10.1579H7.4026C10.7153 10.1579 13.3092 8.8276 14.0673 4.97912C14.09 4.86518 14.109 4.75427 14.1262 4.64578C13.9343 4.54518 13.7265 4.45912 13.5028 4.38578C13.4477 4.3676 13.3907 4.35003 13.3331 4.33306Z'
        fill='#222D65'
      />
      <path
        d='M5.89244 4.66508C5.92983 4.4269 6.08428 4.23236 6.29206 4.13417C6.38706 4.08933 6.49186 4.06448 6.6028 4.06448H11.1089C11.6427 4.06448 12.141 4.09902 12.5958 4.17175C12.7257 4.19236 12.852 4.21599 12.9752 4.24266C13.0977 4.26993 13.2172 4.30024 13.3331 4.33357C13.3907 4.35054 13.4477 4.36811 13.5035 4.38569C13.7272 4.45902 13.935 4.54569 14.1268 4.64569C14.3523 3.22327 14.125 2.25478 13.3472 1.37781C12.4897 0.412357 10.9422 -0.000976562 8.96186 -0.000976562H3.21283C2.80832 -0.000976562 2.46325 0.289933 2.40073 0.68569L0.00612218 15.6948C-0.0410714 15.9917 0.190606 16.2596 0.49338 16.2596H4.0427L4.93386 10.6687L5.89244 4.66508Z'
        fill='#253B80'
      />
    </g>
    <defs>
      <clipPath id='clip0_1855_2851'>
        <rect width={width} height={height} fill='white' />
      </clipPath>
    </defs>
  </svg>
)

const propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
}

const defaultProps = {
  width: 76,
  height: 20
}

PayPalIcon.propTypes = propTypes
PayPalIcon.defaultProps = defaultProps
export default PayPalIcon
