export const BE_CREDIT_CARD = 1
export const BE_BANK_TRANSFER = 2
export const BE_MONRI = 4
export const BE_STRIPE = 3
export const BE_M2PAY = 5
export const BE_WSPAY = 6
export const BE_SAFERPAY = 7

// Saferpay Payment Page methods have id as a string `${BE_SAFERPAY}${method id}` eg. 7googlepay, 7clicktopay, 7paypal
// so we just check the first character to be 7 (BE_SAFERPAY)
export const isSaferpayPaymentPage = selectedPaymentMethod =>
  String(selectedPaymentMethod).length > 1 && +String(selectedPaymentMethod)[0] === BE_SAFERPAY
