import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import OfferPayment from 'Rentlio/screen/offerPayment/components/OfferPayment'
import { getOfferReceipt } from 'Rentlio/screen/offerPayment/util/offerReceipt'
import {
  acceptBookingOffer,
  getMonriClientSecret,
  getStripeClientSecret,
  getM2PayClientSecret,
  saferpayAliasInsert,
  saferpayPaymentPageInitialize
} from 'Rentlio/state/actions/api'
import {
  openPrivacyPolicyModal,
  openTermsOfServiceModal,
  setPricePerDayReservationIndex,
  updatePaymentInfo,
  openM2Pay3DSModal,
  openSaferpay3DSModal
} from 'Rentlio/state/actions/ui'
import { isOfferStillActive } from 'Rentlio/utils/bookingTypeEnum'
import { OFFER_PAGE } from 'Rentlio/utils/routes'

const OfferPaymentContainer = props => {
  // eslint-disable-next-line react/prop-types
  const { offer, booking } = props

  if (!isOfferStillActive(offer, booking)) {
    // eslint-disable-next-line react/prop-types
    const path = OFFER_PAGE.replaceAll(':offerId', offer.offerUuid).replaceAll(':bookingId', booking.code)
    return <Redirect to={path} />
  }
  return <OfferPayment {...props} />
}

const mapStateToProps = state => {
  const {
    bookingEngine,
    paymentDetails,
    offer,
    booking,
    paymentMethods,
    pricePerDay,
    monri,
    stripe,
    m2pay,
    configuration
  } = state

  return {
    bookingEngine,
    paymentDetails,
    offer,
    booking,
    paymentMethods,
    pricePerDay,
    paymentGateways: { monri, stripe, m2pay },
    receipt: getOfferReceipt(booking, bookingEngine),
    locale: configuration.locale
  }
}

const mapDispatchToProps = dispatch => ({
  openTermsOfServiceModal: () => dispatch(openTermsOfServiceModal()),
  openPrivacyPolicyModal: () => dispatch(openPrivacyPolicyModal()),
  acceptBookingOffer: (offerUuid, bookingId, data) => dispatch(acceptBookingOffer(offerUuid, bookingId, data)),
  setPricePerDayReservationIndex: reservationIndex => dispatch(setPricePerDayReservationIndex(reservationIndex)),
  updatePaymentInfo: paymentDetails => dispatch(updatePaymentInfo(paymentDetails)),
  getMonriClientSecret: (authenticityToken, bookingEngineId, amount, currency, isWSPay) =>
    dispatch(getMonriClientSecret(authenticityToken, bookingEngineId, amount, currency, isWSPay)),
  getStripeClientSecret: (publishableApiKey, bookingEngineId, amount, currency, guestDetails) =>
    dispatch(getStripeClientSecret(publishableApiKey, bookingEngineId, amount, currency, guestDetails)),
  openM2Pay3DSModal: data => dispatch(openM2Pay3DSModal(data)),
  getM2PayClientSecret: (merchantId, bookingEngineId) => dispatch(getM2PayClientSecret(merchantId, bookingEngineId)),
  saferpayAliasInsert: payload => dispatch(saferpayAliasInsert(payload)),
  openSaferpay3DSModal: data => dispatch(openSaferpay3DSModal(data)),
  saferpayPaymentPageInitialize: data => dispatch(saferpayPaymentPageInitialize(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(OfferPaymentContainer)
