import moment from 'moment'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  IncludedExtrasWrapper,
  InformationWrapper,
  Label,
  LinkText,
  LinkWrapper,
  OccupancyDivider,
  OccupancyItem,
  OccupancyLabel,
  OccupancyValue,
  OccupancyWrapper,
  Price,
  PriceCurrency,
  PriceIconWrapper,
  PriceItem,
  PriceLabel,
  PriceRow,
  SingleRatePlanWrapper,
  Title,
  Value
} from './style'
import Discount from './Discount'
import { DATE_FORMAT } from 'Rentlio/utils/dateFormatEnum'
import { formatPrice } from 'Rentlio/components/utils/Price/price'
import { CurrenciesContext } from 'Rentlio/context/CurrenciesContext'
import { LanguageContext } from 'Rentlio/context/LanguageContext'
import PricePerDayModal from 'Rentlio/screen/offer/components/Offer/Reservation/RatePlan/PricePerDayModal'
import Animation from 'Rentlio/components/UI/Animation'
import Modal from 'Rentlio/components/Modal'
import ArrowRight from 'Rentlio/components/Icons/arrowLongRight'
import colors from 'Rentlio/style/colors'
import Plus from 'Rentlio/components/Icons/plus'
import Union from 'Rentlio/components/Icons/union'

class RatePlan extends Component {
  static contextType = LanguageContext

  state = {
    showModal: false
  }

  getPeriodLabel = () => {
    const {
      reservation: { arrivalDate, departureDate }
    } = this.props

    return `${moment
      .unix(arrivalDate)
      .utc()
      .format(DATE_FORMAT)} - ${moment
      .unix(departureDate)
      .utc()
      .format(DATE_FORMAT)}`
  }

  getFilteredExtras = extras => {
    // Don't include taxes (different from touristTax) and accommodation price from pricePerDay property, t
    // these are not categorized as "extras" inside rate plan
    return Object.keys(extras)
      .filter(key => !['taxes', 'accommodation'].includes(key))
      .reduce((object, key) => {
        object[key] = extras[key]
        return object
      }, {})
  }

  getExtrasLabel = extras => {
    if (!extras || Object.keys(extras).length === 0) {
      return ''
    }

    const { translate } = this.context
    const filteredExtras = (({ fees, services, touristTaxes }) => [fees, services, touristTaxes].flat())(
      this.getFilteredExtras(extras)
    )

    return filteredExtras
      .map(item => {
        return `${item.name} ${Number.parseFloat(item.total) === 0 ? `(${translate('Included')})` : ''}`
      })
      .join(', ')
  }

  getExtrasAmount = extras => {
    if (!extras || Object.keys(extras).length === 0) {
      return 0
    }

    return Object.values(this.getFilteredExtras(extras)).reduce((total, extra) => {
      return (
        total +
        extra.reduce((totalItem, item) => {
          return totalItem + item.total
        }, 0)
      )
    }, 0)
  }

  showModalHandler = () => {
    this.setState({ showModal: true })
  }

  hideModalHandler = () => {
    this.setState({ showModal: false })
    document.body.classList.remove('modal-open')
  }

  render() {
    const { translate } = this.context
    const { reservation } = this.props
    const { showModal } = this.state

    return (
      <CurrenciesContext.Consumer>
        {({ currencyConversion }) => {
          const { value: totalRate, currency: rateCurrency } = currencyConversion(
            reservation.totalReservationPrice,
            reservation.currency
          )

          const { value: accommodationRate } = currencyConversion(
            reservation.totalAccommodationPrice,
            reservation.currency
          )

          const { value: extrasRate } = currencyConversion(
            this.getExtrasAmount(reservation.pricesPerDay),
            reservation.currency
          )

          return (
            <>
              <Animation show={showModal} animationType={'fadeInOut'} isModal>
                <Modal hideModalHandler={this.hideModalHandler} show={true} isLarge padding={'50px 30px'}>
                  <PricePerDayModal reservation={reservation} />
                </Modal>
              </Animation>
              <SingleRatePlanWrapper>
                <InformationWrapper>
                  <Title>{reservation.ratePlanName}</Title>
                  <LinkWrapper onClick={() => this.showModalHandler()}>
                    <LinkText>{translate('See prices by day')}</LinkText>
                    <ArrowRight stroke={colors.secondary} width={8} height={6} />
                  </LinkWrapper>
                </InformationWrapper>
                <OccupancyWrapper>
                  <OccupancyItem>
                    <OccupancyLabel alignLeft>{translate('Period')}</OccupancyLabel>
                    <OccupancyValue alignLeft>{this.getPeriodLabel()}</OccupancyValue>
                    <OccupancyDivider />
                  </OccupancyItem>
                  <OccupancyItem>
                    <OccupancyLabel>{translate('Adults')}</OccupancyLabel>
                    <OccupancyValue>{reservation.adults}</OccupancyValue>
                    <OccupancyDivider />
                  </OccupancyItem>
                  <OccupancyItem>
                    <OccupancyLabel>{translate('Children')}</OccupancyLabel>
                    <OccupancyValue>{reservation.children}</OccupancyValue>
                    <OccupancyDivider />
                  </OccupancyItem>
                  <OccupancyItem>
                    <OccupancyLabel>{translate('Units')}</OccupancyLabel>
                    <OccupancyValue>{reservation.unitsNumber || 1}</OccupancyValue>
                  </OccupancyItem>
                </OccupancyWrapper>
                {/* {ratePlan.activePromoCode !== null && <Promo ratePlan={ratePlan} unitTypeCurrency={unitTypeCurrency} />} */}
                <PriceRow>
                  {reservation?.discounts?.length > 0 && (
                    <Discount reservation={reservation} rateCurrency={rateCurrency} />
                  )}
                  <PriceItem>
                    <PriceLabel>{translate('Accommodation')}</PriceLabel>
                    <Price>
                      {formatPrice(accommodationRate)}
                      <PriceCurrency>{rateCurrency}</PriceCurrency>
                    </Price>
                  </PriceItem>
                  {!!extrasRate && (
                    <>
                      <PriceIconWrapper>
                        <Plus useTeritaryColor />
                      </PriceIconWrapper>
                      <PriceItem>
                        <PriceLabel>{translate('Extras')}</PriceLabel>
                        <Price>
                          {formatPrice(extrasRate)}
                          <PriceCurrency>{rateCurrency}</PriceCurrency>
                        </Price>
                      </PriceItem>
                    </>
                  )}
                  <PriceIconWrapper>
                    <Union useTeritaryColor />
                  </PriceIconWrapper>
                  <PriceItem>
                    <PriceLabel>{translate('Total per unit')}</PriceLabel>
                    <Price>
                      {formatPrice(totalRate)}
                      <PriceCurrency>{rateCurrency}</PriceCurrency>
                    </Price>
                  </PriceItem>
                </PriceRow>
                {!!this.getExtrasLabel(reservation.pricesPerDay) && (
                  <IncludedExtrasWrapper>
                    <Label>{translate('Extras')}:</Label>
                    <Value>{this.getExtrasLabel(reservation.pricesPerDay)}</Value>
                  </IncludedExtrasWrapper>
                )}
                {/* <CancellationPolicyWrapper> */}
                {/*   /!* <CancellationPolicy *!/ */}
                {/*   /!*  policy={ratePlan.policy} *!/ */}
                {/*   /!*  translate={translate} *!/ */}
                {/*   /!*  totalRatePrice={totalRate} *!/ */}
                {/*   /!*  unitTypeCurrency={totalCurrency} *!/ */}
                {/*   /!*  arrivalDate={arrivalDate} *!/ */}
                {/*   /!* /> *!/ */}
                {/* </CancellationPolicyWrapper> */}
              </SingleRatePlanWrapper>
            </>
          )
        }}
      </CurrenciesContext.Consumer>
    )
  }
}

RatePlan.propTypes = {
  reservation: PropTypes.object
}

export default RatePlan
