import styled, { css } from 'styled-components'

export const RadioGroupWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  flex-wrap: wrap;
  gap: 30px;
`
export const Radio = styled.div`
  display: flex;
  align-items: center;
  min-width: 160px;
  height: 50px;
  padding: 20px 15px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 16px;
  transition: all ${({ theme }) => theme.animations.defaultTransition};
  cursor: pointer;

  :hover {
    border: 1px solid ${props => props.theme.colors.secondary};
    outline: none;
  }

  ${({ active, theme }) =>
    active
      ? css`
          background-color: ${theme.colors.radioCheckBoxActiveBg};
          border: 1px solid ${props => props.theme.colors.secondary};
        `
      : css`
          background-color: ${theme.colors.white};
        `}
`
export const RadioButton = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 100px;
  ${({ active, theme }) =>
    active
      ? css`
          background-color: ${theme.colors.buttonBackground};
          border: none;
        `
      : css`
          border: 1px solid ${({ theme }) => theme.colors.border};
          background-color: ${theme.colors.white};
        `};
`
export const RadioLabel = styled.label`
  color: ${({ theme }) => theme.colors.veryDarkGray};
  font-size: ${({ theme }) => theme.fonts._14};
  font-weight: ${({ theme }) => theme.fonts.mediumWeight};
  margin-left: 15px;
  cursor: pointer;
`
