import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Header from './Header'
import {
  GuestRegistrationWrapper,
  ButtonContainer,
  ButtonWrapper,
  SpinnerOverlay,
  FormErrorsWrapper,
  UpdatedMessage,
  InfoText
} from './style'

import Guest from './Guest'
import HeaderMobile from './HeaderMobile'
import { dateOfBirthValidator } from 'Rentlio/helper/validator/dateOfBirth'
import { GoldButton } from 'Rentlio/components/UI/button'
import { LanguageContext } from 'Rentlio/context/LanguageContext'
import Animation from 'Rentlio/components/UI/Animation'
import Spinner from 'Rentlio/components/UI/Spinner'
import { validateData } from 'Rentlio/helper/validator'
import { emailConstraint } from 'Rentlio/helper/validator/email'
import { maxLengthConstraint } from 'Rentlio/helper/validator/maxLength'
import { notEmptyConstraint } from 'Rentlio/helper/validator/notEmpty'
import { minLengthConstraint } from 'Rentlio/helper/validator/minLength'
import { CONFIRMED } from 'Rentlio/utils/reservationStatusEnum.js'

const GuestRegistration = ({ guests, updateGuestRegistration, reservation, locale }) => {
  const { translate } = useContext(LanguageContext)
  const [loading, setLoading] = useState(false)
  const [errorsPrimaryGuests, setErrorsPrimaryGuests] = useState({})
  const [updateSuccessful, setUpdateSuccessful] = useState(false)
  const [uuid, setUuid] = useState('')
  const [stateGuests, setStateGuests] = useState([])

  useEffect(() => {
    const urlPath = window.location.pathname
    const uuidRegex = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/
    const foundUuid = urlPath.match(uuidRegex)
    if (foundUuid) {
      setUuid(foundUuid[0])
    }

    const mappedGuests = guests.map(guest => ({
      ...guest,
      dateOfBirth: guest.dateOfBirth !== null && moment.utc(guest.dateOfBirth * 1000).format('DD.MM.YYYY')
    }))
    setStateGuests(mappedGuests)
  }, [guests])

  const shouldValidateGuest = guest => {
    if (guest.isPrimary) {
      return true
    }
    return Object.entries(guest).some(
      ([key, value]) => key !== 'isPrimary' && key !== 'id' && value !== '' && value !== null && value !== false
    )
  }

  const getErrors = guestsPayload => {
    const allErrors = {}
    guestsPayload.forEach((guest, index) => {
      if (shouldValidateGuest(guest)) {
        const validationRules = {
          contactNumber: [val => maxLengthConstraint(val, 45)],
          countryOfBirthId: [notEmptyConstraint],
          travelDocumentTypesId: [notEmptyConstraint],
          genderId: [notEmptyConstraint],
          documentNumber: [notEmptyConstraint, val => maxLengthConstraint(val, 18)],
          city: [notEmptyConstraint, val => maxLengthConstraint(val, 30)],
          dateOfBirth: [notEmptyConstraint, val => minLengthConstraint(val, 10), dateOfBirthValidator],
          name: [notEmptyConstraint, val => maxLengthConstraint(val, 100)],
          email: [val => maxLengthConstraint(val, 150), emailConstraint]
        }

        const guestsErrors = validateData(guest, validationRules)

        if (guestsErrors && Object.keys(guestsErrors).length > 0) {
          allErrors[index] = guestsErrors
        }
      }
    })

    return allErrors
  }

  const getPayload = guests => {
    return guests.map(guest => ({
      ...guest,
      dateOfBirth: guest.dateOfBirth || null
    }))
  }
  const saveGuests = () => {
    setLoading(true)
    // Get the errors for guests
    const guestsErrors = getErrors(stateGuests)
    let hasErrors = false

    if (Object.keys(guestsErrors).length > 0) {
      setErrorsPrimaryGuests(guestsErrors)
      setLoading(false)
      hasErrors = true
    } else {
      setErrorsPrimaryGuests({})
    }

    if (!hasErrors) {
      updateGuestRegistration(getPayload(stateGuests), uuid)
        .then(({ success }) => {
          if (success) {
            setUpdateSuccessful(true)
            window.scrollTo(0, 0)
            setTimeout(scrollToUpdatedMessage, 100)
          }
        })
        .finally(() => setLoading(false))
    }
  }

  const scrollToUpdatedMessage = () => {
    const updatedMessageElement = document.querySelector('#guestRegistrationWrapper')
    if (updatedMessageElement) {
      updatedMessageElement.scrollIntoView({ top: -100, behavior: 'smooth' })
    }
  }

  const isEmpty = obj => {
    // Check if object has any properties of its own
    return Object.keys(obj).length === 0 && obj.constructor === Object
  }

  const updateGuestField = (index, fieldName, value) => {
    const updatedGuests = [...stateGuests]
    updatedGuests[index] = { ...updatedGuests[index], [fieldName]: value }
    setStateGuests(updatedGuests)
  }

  return (
    <GuestRegistrationWrapper id='guestRegistrationWrapper'>
      <Animation show={loading} animationType={'fadeInOut'} isModal>
        <SpinnerOverlay>
          <Spinner width={70} disableScroll color={'#07587A'} />
        </SpinnerOverlay>
      </Animation>
      <Header reservation={reservation} />
      <HeaderMobile reservation={reservation} />
      {!updateSuccessful && (
        <>
          {stateGuests.map((guest, index) => (
            <Guest
              key={index}
              guest={guest}
              index={index}
              updateGuestField={updateGuestField}
              errors={errorsPrimaryGuests[index] || {}}
              formDisabled={reservation.status !== CONFIRMED}
              locale={locale}
            />
          ))}

          <ButtonContainer>
            {reservation.status === CONFIRMED && (
              <ButtonWrapper>
                <GoldButton onClick={() => saveGuests()}>{translate('Save guests')}</GoldButton>
              </ButtonWrapper>
            )}

            {reservation.status !== CONFIRMED && (
              <InfoText>
                {translate('This reservation is checked in so it is not possible to edit guest details anymore')}
              </InfoText>
            )}

            {!isEmpty(errorsPrimaryGuests) && (
              <FormErrorsWrapper>
                <div>{translate('The form has errors, please check above.')}</div>
              </FormErrorsWrapper>
            )}
          </ButtonContainer>
        </>
      )}
      {updateSuccessful && <UpdatedMessage>{translate('Your application has been sent. Thank you!')}</UpdatedMessage>}{' '}
    </GuestRegistrationWrapper>
  )
}

GuestRegistration.propTypes = {
  guests: PropTypes.array,
  updateGuestField: PropTypes.func,
  updateGuestRegistration: PropTypes.func,
  reservation: PropTypes.object,
  locale: PropTypes.string
}

export default GuestRegistration
