import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { DiscountBadge, DiscountOriginalPrice, DiscountRow } from './style'
import { DISCOUNT_TYPE_ENUM } from 'Rentlio/utils/discountTypeEnum'
import { CurrenciesContext } from 'Rentlio/context/CurrenciesContext'
import { formatPrice } from 'Rentlio/components/utils/Price/price'

const Discount = ({ reservation, rateCurrency }) => {
  const { currencyConversion } = useContext(CurrenciesContext)
  const { value, type, name } = reservation.discounts[0]

  let totalAccomodationRateBeforeDiscount = 0
  if (type === DISCOUNT_TYPE_ENUM.PERCENTAGE) {
    totalAccomodationRateBeforeDiscount = reservation.totalAccommodationPrice / (1 - +value / 100)
  }
  if (type === DISCOUNT_TYPE_ENUM.NOMINAL) {
    totalAccomodationRateBeforeDiscount = reservation.totalAccommodationPrice + +value
  }

  totalAccomodationRateBeforeDiscount = currencyConversion(totalAccomodationRateBeforeDiscount, rateCurrency)
  const discountAmountConverted = currencyConversion(+value, rateCurrency)

  return (
    <DiscountRow>
      <DiscountBadge>
        {name}{' '}
        {type === DISCOUNT_TYPE_ENUM.NOMINAL
          ? formatPrice(discountAmountConverted.value) + ' ' + rateCurrency
          : formatPrice(value) + '%'}
      </DiscountBadge>
      <DiscountOriginalPrice>
        {formatPrice(totalAccomodationRateBeforeDiscount.value)} {rateCurrency}
      </DiscountOriginalPrice>
    </DiscountRow>
  )
}

Discount.propTypes = {
  reservation: PropTypes.object,
  rateCurrency: PropTypes.string
}

export default Discount
