import React from 'react'
import PropTypes from 'prop-types'
import { PriceTableWrapper, HeadItem, TableHead, TableBody, TableFooter, Text, TotalWrapper } from './style'
import { formatPrice } from 'Rentlio/components/utils/Price/price'
import { LanguageContext } from 'Rentlio/context/LanguageContext'

class PriceTable extends React.PureComponent {
  static contextType = LanguageContext

  render() {
    const { tableHeader, children, tableFooter } = this.props
    const { translate } = this.context

    return (
      <PriceTableWrapper>
        <TableHead>
          {tableHeader.map(item => {
            return (
              <HeadItem
                className={'row-item'}
                key={item?.name}
                width={item?.width}
                offsetRight={item?.offsetRight}
                alignRight={item?.alignRight}
              >
                <Text>{translate(item.name)}</Text>
              </HeadItem>
            )
          })}
        </TableHead>
        <TableBody>{children}</TableBody>
        <TableFooter>
          <Text>{translate(tableFooter?.name)}</Text>
          <TotalWrapper>
            <Text>{translate('Total')}</Text>
            <Text>
              {formatPrice(tableFooter?.total)} {tableFooter?.currency}
            </Text>
          </TotalWrapper>
        </TableFooter>
      </PriceTableWrapper>
    )
  }
}

PriceTable.propTypes = {
  tableHeader: PropTypes.array,
  tableFooter: PropTypes.object,
  children: PropTypes.node
}

export default PriceTable
