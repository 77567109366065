import { addItemToBasket } from 'Rentlio/state/actions/basket/ui'
import { updateServiceQuantity } from 'Rentlio/state/actions/ui'
import { ADD_ITEM_TO_BASKET, CLEAR_BASKET, REMOVE_ITEM_FROM_BASKET, UPDATE_SERVICE_QUANTITY } from 'Rentlio/state/types'

const BASKET_STORAGE_KEY = 'booking-engine-basket-'

const basketPersister = store => next => action => {
  // dont break the normal flow - this has to be called
  next(action)

  // check we are executing logic only on basket related action
  const { type } = action
  if (
    type !== ADD_ITEM_TO_BASKET &&
    type !== REMOVE_ITEM_FROM_BASKET &&
    type !== CLEAR_BASKET &&
    type !== UPDATE_SERVICE_QUANTITY
  ) {
    return
  }

  const { basket, bookingEngine } = store.getState()

  const basketStorageData = basket.map(
    ({ unitTypeId, ratePlanId, services, adults, childrenAges, amenities, fromGoogleTravel }) => ({
      unitTypeId,
      ratePlanId,
      adults,
      childrenAges,
      services: services
        .filter(({ quantity }) => quantity > 0)
        .map(({ id, quantity, isMandatoryQuantityUpdate }) => ({ id, quantity, isMandatoryQuantityUpdate })),
      amenities,
      fromGoogleTravel
    })
  )

  // Important save as static key + slug, so users basket is not overwritten for each potential BE
  window.localStorage &&
    window.localStorage.setItem(BASKET_STORAGE_KEY + bookingEngine.slug, JSON.stringify(basketStorageData))
}

export const updateBasketFromStorage = store => {
  const { bookingEngine, unitTypes } = store.getState()

  // Important load as static key + slug, so users basket is not overwritten for each potential BE
  const key = BASKET_STORAGE_KEY + bookingEngine.slug
  const basketStorage = window.localStorage ? JSON.parse(window.localStorage.getItem(key)) : null

  if (basketStorage === null) {
    return
  }

  basketStorage
    // merge same unitTypeId & ratePlanId into singleArray with quantity
    .reduce((reducedArray, currentItem) => {
      const index = reducedArray.findIndex(
        item => item.unitTypeId === currentItem.unitTypeId && item.ratePlanId === currentItem.ratePlanId
      )

      if (index === -1) {
        reducedArray.push({
          ...currentItem,
          quantity: 1,
          services: [currentItem.services]
        })

        return reducedArray
      }

      reducedArray[index].quantity++
      reducedArray[index].services.push(currentItem.services)
      reducedArray[index].amenities = [...new Set([...reducedArray[index].amenities, ...currentItem.amenities])]

      return reducedArray
    }, [])
    // Filter only unitTypes that are available & rates that are bookable
    .filter(item => {
      const unitType = unitTypes.find(unitTypeItem => unitTypeItem.unitTypeId === item.unitTypeId)

      if (typeof unitType === 'undefined' || !unitType.available) {
        return false
      }

      const rate = unitType.rates.find(currentRate => currentRate.ratePlanId === item.ratePlanId)

      if (typeof rate === 'undefined' || !rate.isBookable) {
        return false
      }

      return true
    })
    // reduce quantity if there are no available units
    .map(item => {
      const { availableUnits } = unitTypes.find(unitTypeItem => unitTypeItem.unitTypeId === item.unitTypeId)

      const quantity = item.quantity > availableUnits ? availableUnits : item.quantity
      return { ...item, quantity }
    })
    // Add surviving items to basket
    .map(item => {
      store.dispatch(
        addItemToBasket(
          item.unitTypeId,
          item.ratePlanId,
          item.quantity,
          item.adults,
          item.childrenAges,
          true,
          item.amenities,
          item.fromGoogleTravel
        )
      )

      const { basket } = store.getState()
      basket
        .filter(basketItem => basketItem.unitTypeId === item.unitTypeId && basketItem.ratePlanId === item.ratePlanId)
        .map((basketItem, i) => {
          if (typeof item.services[i] !== 'undefined' && Array.isArray(item.services[i])) {
            item.services[i].map(({ id, quantity, isMandatoryQuantityUpdate }) => {
              store.dispatch(updateServiceQuantity(basketItem.basketId, id, quantity, isMandatoryQuantityUpdate))
            })
          }
          return basketItem
        })
    })
}

export default basketPersister
