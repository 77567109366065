const add = (key, data) => {
  const { bookingEngine } = window.store.getState()

  window.localStorage &&
    window.localStorage.setItem('booking-engine-' + bookingEngine.slug + '-' + key, JSON.stringify(data))
}

const get = key => {
  const { bookingEngine } = window.store.getState()

  const data = window.localStorage && window.localStorage.getItem('booking-engine-' + bookingEngine.slug + '-' + key)

  if (!data || data === 'undefined') {
    return null
  }

  return JSON.parse(data)
}

const remove = key => {
  const { bookingEngine } = window.store.getState()

  return window.localStorage && window.localStorage.removeItem('booking-engine-' + bookingEngine.slug + '-' + key)
}

export default { add, get, remove }
