import { ADD_ITEM_TO_BASKET } from 'Rentlio/state/types'
import {
  getTotalRate,
  getAverageRate
} from 'Rentlio/screen/accomodation/component/RatePlans/SingleRatePlan/helper/getTotalRate'
import { getTouristTax } from 'Rentlio/components/ReservationSummary/util/getTouristTax.js'

export const addItemToBasket = (
  unitTypeId,
  ratePlanId,
  quantity,
  adults,
  childrenAges,
  isLoadedFromSession,
  amenities,
  fromGoogleTravel
) =>
  function(dispatch, getState) {
    const { unitTypes, services, fees, touristTaxes, configuration } = getState()

    const selectedAmenities = amenities || configuration.selectedAmenities
    const fromGT = fromGoogleTravel || configuration.fromGoogleTravel

    const selectedUnitType = unitTypes.find(unitType => unitType.unitTypeId === unitTypeId)

    if (selectedUnitType === undefined) {
      return
    }

    const selectedRatePlan = selectedUnitType.rates.find(ratePlan => ratePlan.ratePlanId === ratePlanId)

    if (selectedRatePlan === undefined) {
      return
    }

    const totalRate = getTotalRate(selectedUnitType, selectedRatePlan, adults, childrenAges)
    const averageRatePerNight = getAverageRate(selectedUnitType, selectedRatePlan, adults, childrenAges)

    let totalRateBeforeTax = totalRate
    const { bookingEngine } = selectedUnitType

    if (bookingEngine.isVatRegistered && bookingEngine.vatRate > 0 && !selectedRatePlan.isTouristTaxIncluded) {
      totalRateBeforeTax = totalRate / (bookingEngine.vatRate / 100 + 1)
    }
    // if tourist tax is included in rate we need to reduct tourist tax price form total and then calculate VAT because vat is not paid on tourist tax
    if (bookingEngine.isVatRegistered && bookingEngine.vatRate > 0 && selectedRatePlan.isTouristTaxIncluded) {
      totalRateBeforeTax =
        (totalRate - getTouristTax(touristTaxes, adults, childrenAges)) / (bookingEngine.vatRate / 100 + 1)
    }

    return dispatch({
      type: ADD_ITEM_TO_BASKET,
      quantity: quantity,
      payload: {
        isLoadedFromSession: isLoadedFromSession || false,
        bookingEngineId: selectedUnitType.bookingEngine.id,
        unitTypeId: selectedUnitType.unitTypeId,
        currency: selectedUnitType?.bookingEngine?.currency,
        isVatRegistered: selectedUnitType?.bookingEngine?.isVatRegistered,
        vatRate: selectedUnitType?.bookingEngine?.vatRate,
        unitTypeName: selectedUnitType.unitTypeName,
        description: selectedUnitType.description,
        images: selectedUnitType.images,
        ratePlanId: selectedRatePlan.ratePlanId,
        ratePlanName: selectedRatePlan.ratePlanName,
        totalRate: totalRate,
        totalRateBeforeTax: totalRateBeforeTax,
        averageRatePerNight: averageRatePerNight,
        adults: adults,
        childrenAges: childrenAges,
        cancellationPolicy: selectedRatePlan.policy,
        checkInBegin: selectedUnitType?.checkInBegin,
        checkInEnd: selectedUnitType?.checkInEnd,
        checkOutBegin: selectedUnitType?.checkOutBegin,
        checkOutEnd: selectedUnitType?.checkOutEnd,
        includedServices: selectedRatePlan.includedServices || [],
        fees: fees
          .filter(
            ({ ratePlanIds, unitTypeIds }) =>
              (ratePlanIds.length === 0 || ratePlanIds.includes(selectedRatePlan.ratePlanId)) &&
              (unitTypeIds.length === 0 || unitTypeIds.includes(selectedUnitType.unitTypeId))
          )
          .map(fee => ({ ...fee })),
        services: services
          .filter(service => service.bookingEngineId === selectedUnitType.bookingEngine.id)
          .map(service => {
            return {
              id: service.id,
              paymentType: service.paymentType,
              name: service.name,
              description: service.description,
              priceBeforeTax: service.priceBeforeTax,
              price: service.priceAfterTax,
              isMandatory: service.isMandatory,
              quantity: service.isMandatory ? 1 : 0,
              pricingType: service.pricingType,
              pricingDynamic: service.pricingDynamic,
              childrenDifferentThanAdults: service.childrenDifferentThanAdults,
              childrenCategories: service.childrenCategories,
              dailyPrices: service.dailyPrices
            }
          }),
        // if we have tourist tax included in rate we will not display tourist tax price on BE side, we will calculate accommodation and tourist tax on webapp side
        touristTax: getTouristTax(touristTaxes, adults, childrenAges),
        isTouristTaxIncluded: selectedRatePlan.isTouristTaxIncluded,
        amenities: selectedAmenities,
        fromGoogleTravel: fromGT,
        activePromoCode: selectedRatePlan.activePromoCode || null
      }
    })
  }
