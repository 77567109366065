import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { RadioGroupWrapper, Radio, RadioButton, RadioLabel } from './style'
import Checkmark from 'Rentlio/components/Icons/checkmarkVisible'
import { LanguageContext } from 'Rentlio/context/LanguageContext'

const RadioGroup = ({ selected, options, onSelect, disabled }) => {
  const { translate } = useContext(LanguageContext)
  return (
    <RadioGroupWrapper>
      {options.map(option => (
        <Radio
          key={option.id}
          active={option.id === selected}
          onClick={() => !disabled && onSelect(option.id)}
          disabled={disabled}
        >
          <RadioButton active={option.id === selected}>{option.id === selected && <Checkmark />}</RadioButton>
          {option.component ? option.component : <RadioLabel>{translate(option.name)}</RadioLabel>}
        </Radio>
      ))}
    </RadioGroupWrapper>
  )
}

RadioGroup.propTypes = {
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  options: PropTypes.array,
  onSelect: PropTypes.func,
  disabled: PropTypes.bool
}

RadioGroup.defaultProps = {
  disabled: false
}

export default RadioGroup
