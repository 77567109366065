import moment from 'moment'
import { trans } from 'Rentlio/helper/translation'

export function dateOfBirthValidator(value) {
  if (typeof value !== 'string') {
    return trans('The date format is incorrect')
  }

  const datePattern = /^\d{2}\.\d{2}\.\d{4}$/
  if (!datePattern.test(value)) {
    return trans('The date format is incorrect')
  }

  const isValidDate = moment(value, 'DD.MM.YYYY', true).isValid()

  if (!isValidDate) {
    return trans('The date is invalid')
  }

  if (moment(value, 'DD.MM.YYYY', true).year() < 1905) {
    return trans('Date of birth year has to be bigger than 1904')
  }

  if (moment(value, 'DD.MM.YYYY', true).isAfter(moment().add(1, 'day'))) {
    return trans('Date of birth cannot be in the future. Please enter a valid date.')
  }

  return null
}
