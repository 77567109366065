import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import Saferpay3DSHandler from '../components/Payment/Saferpay/components/Saferpay3DSHandler'
import Payment from 'Rentlio/screen/payment/components/Payment'
import {
  bookReservation,
  getMonriClientSecret,
  getStripeClientSecret,
  getM2PayClientSecret,
  saferpayAliasInsert,
  saferpayPaymentPageInitialize
} from 'Rentlio/state/actions/api'
import {
  clearBasket,
  openPrivacyPolicyModal,
  openTermsOfServiceModal,
  sendPaymentFormInputEvent,
  sendSuccessfulReservationEvent,
  updatePaymentInfo,
  openM2Pay3DSModal,
  openSaferpay3DSModal
} from 'Rentlio/state/actions/ui'
import { getReceipt, getPrePaymentDetails } from 'Rentlio/state/selectors/basket'

const PaymentContainer = props => {
  const urlParams = new URLSearchParams(window.location.search)
  const isSaferpay3DSRedirect = urlParams.get('saferpay-3ds-redirect') === 'true'

  if (isSaferpay3DSRedirect) {
    return <Saferpay3DSHandler />
  }

  return <Payment {...props} />
}

PaymentContainer.propTypes = {
  receipt: PropTypes.object,
  request: PropTypes.object,
  bookingEngine: PropTypes.object,
  bookReservation: PropTypes.func,
  updatePaymentInfo: PropTypes.func,
  paymentDetails: PropTypes.object,
  locale: PropTypes.string,
  openTermsOfServiceModal: PropTypes.func,
  openPrivacyPolicyModal: PropTypes.func,
  clearBasket: PropTypes.func,
  getMonriClientSecret: PropTypes.func,
  getStripeClientSecret: PropTypes.func,
  getM2PayClientSecret: PropTypes.func,
  selectedPaymentMethod: PropTypes.func,
  sendSuccessfulReservationEvent: PropTypes.func,
  sendPaymentFormInputEvent: PropTypes.func,
  saferpayPaymentPageInitialize: PropTypes.func
}

const mapStateToProps = state => ({
  bookingEngine: state.bookingEngine,
  receipt: getReceipt(state),
  request: state.request,
  paymentDetails: state.paymentDetails,
  paymentMethods: state.paymentMethods,
  locale: state.configuration.locale,
  prePaymentDetails: getPrePaymentDetails(state),
  monri: state.monri,
  stripe: state.stripe,
  m2pay: state.m2pay,
  touristTaxes: state.touristTaxes
})

const mapDispatchToProps = dispatch => ({
  bookReservation: (guest, payment) => dispatch(bookReservation(guest, payment)),
  updatePaymentInfo: paymentDetails => dispatch(updatePaymentInfo(paymentDetails)),
  openTermsOfServiceModal: () => dispatch(openTermsOfServiceModal()),
  openPrivacyPolicyModal: () => dispatch(openPrivacyPolicyModal()),
  openM2Pay3DSModal: data => dispatch(openM2Pay3DSModal(data)),
  clearBasket: () => dispatch(clearBasket()),
  getMonriClientSecret: (authenticityToken, bookingEngineId, amount, currency, isWSPay) =>
    dispatch(getMonriClientSecret(authenticityToken, bookingEngineId, amount, currency, isWSPay)),
  getStripeClientSecret: (publishableApiKey, bookingEngineId, amount, currency, guestDetails) =>
    dispatch(getStripeClientSecret(publishableApiKey, bookingEngineId, amount, currency, guestDetails)),
  getM2PayClientSecret: (merchantId, bookingEngineId) => dispatch(getM2PayClientSecret(merchantId, bookingEngineId)),
  sendSuccessfulReservationEvent: (internalId, guestDetails) =>
    dispatch(sendSuccessfulReservationEvent(internalId, guestDetails)),
  sendPaymentFormInputEvent: () => dispatch(sendPaymentFormInputEvent()),
  saferpayAliasInsert: payload => dispatch(saferpayAliasInsert(payload)),
  openSaferpay3DSModal: data => dispatch(openSaferpay3DSModal(data)),
  saferpayPaymentPageInitialize: data => dispatch(saferpayPaymentPageInitialize(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentContainer)
