import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import ReceiptItemRow from './ReceiptItemRow'
import Card from './Card'
import {
  ReceiptDivider,
  ReceiptGroupRow,
  ReservationSummaryWrapper,
  Title,
  TotalCost,
  TotalCostLabel,
  TotalCostValue,
  VatInfoRow,
  VatInfoValue,
  ExchangeRate,
  TotalCostValuesWrapper,
  TotalCostSecondaryValue,
  TouristTaxRow,
  MastercardWrapper
} from './style'
import groupAccommodationAndExtrasItems from './util/groupAccommodationAndExtrasItems'
import { getNights } from 'Rentlio/state/selectors/request'
import ReservationDates from 'Rentlio/components/ReservationDates'
import { formatPrice } from 'Rentlio/components/utils/Price/price'
import { LanguageContext } from 'Rentlio/context/LanguageContext'
import { CurrenciesContext } from 'Rentlio/context/CurrenciesContext'
import { shouldShowHrkTotal } from 'Rentlio/helper/currency'
import Mastercard from 'Rentlio/components/Icons/mastercard'

class ReservationSummary extends React.Component {
  static contextType = LanguageContext

  render() {
    const {
      receipt,
      request,
      hasTitle,
      showCard,
      cardHolderName,
      maskedPan,
      vatRate,
      selectedPaymentMethod,
      createdAt,
      bookingEngine,
      touristTaxes
    } = this.props
    const { accomodation, extras } = groupAccommodationAndExtrasItems(receipt)
    const { translate } = this.context
    const touristTaxTotal = receipt?.touristTax?.notIncluded

    return (
      <CurrenciesContext.Consumer>
        {({ currencyConversion, shouldShowExchangeRate }) => {
          const { value: accomodationTotalCost, currency: accomodationCurrency } = currencyConversion(
            accomodation.totalCost,
            receipt.currency
          )

          const { value: extrasTotalCost, currency: extrasCurrency } = currencyConversion(
            extras.totalCost,
            receipt.currency
          )

          const { value: receiptTotal, currency: receiptCurrency } = currencyConversion(
            receipt.price.total,
            receipt.currency
          )

          return (
            <ReservationSummaryWrapper>
              {hasTitle && <Title>{translate('Reservation summary')}</Title>}
              {/* Didnt want to reuse this from Search, composition > inheritance IMHO */}
              <ReservationDates from={request.from} to={request.to} />
              <ReceiptGroupRow>
                <div>{translate('Accommodation')}</div>
                <div>
                  {formatPrice(accomodationTotalCost)} {accomodationCurrency}
                </div>
              </ReceiptGroupRow>
              {accomodation.items.map((item, i) => (
                <Fragment key={i}>
                  <ReceiptItemRow
                    item={item}
                    isAccommodation
                    from={request.from}
                    to={request.to}
                    selectedPaymentMethod={selectedPaymentMethod}
                    createdAt={createdAt}
                  />
                  <ReceiptDivider />
                </Fragment>
              ))}
              <ReceiptGroupRow>
                <div>{translate('Extras')}</div>
                <div>
                  {formatPrice(extrasTotalCost)} {extrasCurrency}
                </div>
              </ReceiptGroupRow>
              {extras.items.map((item, i) => (
                <ReceiptItemRow item={item} key={i} nights={getNights(request)} />
              ))}
              {touristTaxes && touristTaxes.length ? (
                <TouristTaxRow>
                  <div>
                    {translate(
                      touristTaxTotal
                        ? 'Tourist tax is not included in total and is paid at property'
                        : 'Tourist tax is included in price'
                    )}
                  </div>
                  {touristTaxTotal ? (
                    <VatInfoValue>
                      {formatPrice(touristTaxTotal)} {receiptCurrency}
                    </VatInfoValue>
                  ) : null}
                </TouristTaxRow>
              ) : null}
              <TotalCost>
                <TotalCostLabel>{translate('Total cost')}</TotalCostLabel>
                <TotalCostValuesWrapper>
                  <TotalCostValue>
                    {formatPrice(receiptTotal)} {receiptCurrency}
                  </TotalCostValue>
                  {shouldShowHrkTotal(receipt.currency) && (
                    <TotalCostSecondaryValue>
                      {formatPrice(receipt.price.total * (bookingEngine?.settingsExchangeRate || 1))} {'HRK'}
                    </TotalCostSecondaryValue>
                  )}
                </TotalCostValuesWrapper>
              </TotalCost>
              {receipt.isVatRegistered && (
                <VatInfoRow>
                  <div>{translate('AccomodationVATIncluded', { '%vatRate%': receipt.vatRate || vatRate })}</div>
                </VatInfoRow>
              )}
              {receipt.isVatRegistered && (
                <VatInfoRow>
                  <div>{translate('Extras VAT is included in price')}</div>
                </VatInfoRow>
              )}
              {!receipt.isVatRegistered && (
                <VatInfoRow>
                  <div>{translate('Property is not VAT registered, so VAT is not applicable')}</div>
                </VatInfoRow>
              )}
              {shouldShowExchangeRate() && (
                <ExchangeRate>
                  <div>{translate('Exchange rate')}</div>
                  <VatInfoValue>1 EUR = {formatPrice(7.5345, 5)} HRK</VatInfoValue>
                </ExchangeRate>
              )}
              {showCard && <Card cardHolderName={cardHolderName} maskedPan={maskedPan} />}
              <MastercardWrapper>
                {translate('Preferred payment method')}

                <Mastercard width={54} height={45} />
              </MastercardWrapper>
            </ReservationSummaryWrapper>
          )
        }}
      </CurrenciesContext.Consumer>
    )
  }
}

ReservationSummary.propTypes = {
  receipt: PropTypes.object,
  request: PropTypes.object,
  hasTitle: PropTypes.bool,
  showCard: PropTypes.bool,
  cardHolderName: PropTypes.string,
  maskedPan: PropTypes.string,
  isVatRegistered: PropTypes.bool,
  vatRate: PropTypes.number,
  selectedPaymentMethod: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  createdAt: PropTypes.number,
  bookingEngine: PropTypes.object,
  touristTaxes: PropTypes.array
}

ReservationSummary.defaultProps = {
  showCard: false
}

export default ReservationSummary
