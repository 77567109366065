import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Title, InformationWrapper, Information, TotalCost, TotalCostWrapper } from './style'
import { DATE_FORMAT } from 'Rentlio/utils/dateFormatEnum'
import { People } from 'Rentlio/components/Icons/people'
import PriceTable from 'Rentlio/components/UI/PriceTable'
import { Column, Row, Text, SelectedCurrency } from 'Rentlio/components/UI/PriceTable/style'
import { CurrenciesContext } from 'Rentlio/context/CurrenciesContext'
import { formatPrice } from 'Rentlio/components/utils/Price/price'
import { feePricingDynamicOptions } from 'Rentlio/utils/feePricingDynamicEnum'
import { servicePricingDynamicOptions } from 'Rentlio/utils/servicePricingDynamicEnum'
import { LanguageContext } from 'Rentlio/context/LanguageContext'

class PricePerDayModal extends React.PureComponent {
  static contextType = LanguageContext

  getChildrenColumns = children => {
    const { translate } = this.context

    if (!children || children.length === 0) {
      return []
    }

    return children
      .filter(child => Number(child.price) > 0)
      .map(child => {
        return { label: `${translate('Children')} ${child.ageFrom}-${child.ageTo}`, price: child.price }
      })
  }

  getChildrenTableLabels = children =>
    this.getChildrenColumns(children).map(child => {
      return { name: child.label, width: 14, alignRight: true }
    })

  getAccommodationLabels = accommodation => {
    if (!accommodation) {
      return [{ name: 'No accomodation data found', width: 10, offsetRight: true }]
    }

    const { perNight, adults, children } = accommodation

    // If standard rate is per day or per unit
    if (!perNight) {
      return [
        { name: 'Date', width: 10, offsetRight: true },
        { name: 'Total', width: 15, alignRight: true }
      ]
    }

    // Add adults and children labels if exist
    // Flat them to make one main array (multiple children labels)
    // If their values are undefined (child and adults not exist) filter them out
    return [
      { name: 'Date', width: 10, offsetRight: true },
      { name: 'Accommodation', width: 14, alignRight: true },
      adults && { name: 'Adults', width: 12, alignRight: true },
      children && this.getChildrenTableLabels(children),
      { name: 'Total', width: 15, alignRight: true }
    ]
      .flat()
      .filter(item => !!item)
  }

  getTotalAmount = (priceCategory, currentCurrency, currencyConversion) => {
    return priceCategory.reduce((total, item) => {
      const { value: totalPrice } = currencyConversion(item.total, currentCurrency)
      return total + totalPrice
    }, 0)
  }

  getTaxes = taxes => {
    if (!taxes || taxes.length === 0) {
      return false
    }

    const { translate } = this.context

    return taxes
      .map(tax => {
        return `${translate(tax.label)} (${tax.rate}%) ${translate('included in price')}`
      })
      .join(', ')
  }

  getGuests = (adults, children) => {
    if (adults === null || children === null) {
      return false
    }

    const { translate } = this.context

    return (
      (adults > 0 ? `${adults} ${adults === 1 ? translate('Adult') : translate('Adults')} ` : '') +
      (children > 0 ? `, ${children} ${children === 1 ? translate('Child') : translate('Children')} ` : '')
    )
  }

  getReservationDate = (startDate, endDate) => {
    if (startDate === null || endDate === null) {
      return false
    }

    return `(${moment.unix(startDate).format(DATE_FORMAT)} - ${moment.unix(endDate).format(DATE_FORMAT)})`
  }

  render() {
    const { reservation } = this.props
    const { translate } = this.context

    const {
      unitType,
      arrivalDate,
      departureDate,
      adults,
      children,
      pricesPerDay,
      currency,
      totalReservationPrice
    } = reservation
    const { accommodation, services, fees, touristTaxes, taxes } = pricesPerDay

    return (
      <CurrenciesContext.Consumer>
        {({ currencyConversion, selectedCurrency }) => {
          const { value: totalPrice } = currencyConversion(totalReservationPrice, currency)

          return (
            <>
              <Title>{translate('Price breakdown')}</Title>
              <InformationWrapper>
                <Information>
                  {unitType.name} {this.getReservationDate(arrivalDate, departureDate)}
                </Information>
                <Information>
                  <People />
                  <span>{this.getGuests(adults, children)}</span>
                </Information>
              </InformationWrapper>

              {accommodation && accommodation.length > 0 && (
                <PriceTable
                  tableHeader={this.getAccommodationLabels(accommodation[0])}
                  tableFooter={{
                    name: 'Accommodation',
                    total: this.getTotalAmount(accommodation, currency, currencyConversion),
                    currency: selectedCurrency
                  }}
                >
                  {accommodation.map(item => {
                    const childrenColumn = this.getChildrenColumns(item.children)

                    const { value: perNightPrice } = currencyConversion(item.perNight, currency)
                    const { value: adultPrice } = currencyConversion(item.adults, currency)
                    const { value: totalPrice } = currencyConversion(item.total, currency)

                    return (
                      <Row key={item.timestamp}>
                        <Column width={10} offsetRight responsiveLabel={`${translate('Date')}: `}>
                          <Text>{moment.unix(item.timestamp).format(DATE_FORMAT)}</Text>
                        </Column>
                        {!!perNightPrice && (
                          <Column width={14} alignRight responsiveLabel={`${translate('Accommodation')}: `}>
                            <Text>
                              {formatPrice(perNightPrice)} <SelectedCurrency>{selectedCurrency}</SelectedCurrency>
                            </Text>
                          </Column>
                        )}
                        {!!adultPrice && (
                          <Column width={12} alignRight responsiveLabel={`${translate('Adults')}: `}>
                            <Text>
                              {formatPrice(adultPrice)} <SelectedCurrency>{selectedCurrency}</SelectedCurrency>
                            </Text>
                          </Column>
                        )}
                        {childrenColumn &&
                          childrenColumn.length > 0 &&
                          childrenColumn.map((childColumn, index) => {
                            const { value: childrenPrice } = currencyConversion(childColumn.price, currency)
                            return (
                              <Column key={index} width={14} alignRight responsiveLabel={`${childColumn.label}: `}>
                                <Text>
                                  {formatPrice(childrenPrice)} <SelectedCurrency>{selectedCurrency}</SelectedCurrency>
                                </Text>
                              </Column>
                            )
                          })}
                        <Column width={15} alignRight responsiveLabel={`${translate('Total')}: `}>
                          <Text>
                            {formatPrice(totalPrice)} <SelectedCurrency>{selectedCurrency}</SelectedCurrency>
                          </Text>
                        </Column>
                      </Row>
                    )
                  })}
                </PriceTable>
              )}

              {services && services.length > 0 && (
                <PriceTable
                  tableHeader={[
                    { name: 'Service', offsetRight: true },
                    { name: 'Type', width: 30 },
                    { name: 'Quantity', width: 15 },
                    { name: 'Price', width: 20, alignRight: true }
                  ]}
                  tableFooter={{
                    name: 'Services',
                    total: this.getTotalAmount(services, currency, currencyConversion),
                    currency: selectedCurrency
                  }}
                >
                  {services.map((item, index) => {
                    const { value: totalPrice } = currencyConversion(item.total, currency)
                    // TODO:LUKA create helper method for checking if pricingDynamic is null
                    const { label: feePricingTypeLabel } = servicePricingDynamicOptions[item.pricingDynamic - 1] || ''

                    return (
                      <Row key={index}>
                        <Column offsetRight responsiveLabel={translate('Service')}>
                          <Text>{item.name}</Text>
                        </Column>
                        <Column width={30} responsiveLabel={translate('Type')}>
                          <Text>{feePricingTypeLabel}</Text>
                        </Column>
                        <Column width={15} responsiveLabel={translate('Quantity')}>
                          <Text>{item.quantity}</Text>
                        </Column>
                        <Column width={20} alignRight responsiveLabel={translate('Total')}>
                          <Text>
                            {+item.total === 0 ? (
                              translate('Free')
                            ) : (
                              <>
                                {formatPrice(totalPrice)} <SelectedCurrency>{selectedCurrency}</SelectedCurrency>
                              </>
                            )}
                          </Text>
                        </Column>
                      </Row>
                    )
                  })}
                </PriceTable>
              )}

              {fees && fees.length > 0 && (
                <PriceTable
                  tableHeader={[
                    { name: 'Fee', offsetRight: true },
                    { name: 'Type', width: 30 },
                    { name: 'Quantity', width: 15 },
                    { name: 'Price', width: 20, alignRight: true }
                  ]}
                  tableFooter={{
                    name: 'Fees',
                    total: this.getTotalAmount(fees, currency, currencyConversion),
                    currency: selectedCurrency
                  }}
                >
                  {fees.map((item, index) => {
                    const { value: totalPrice } = currencyConversion(item.total, currency)
                    // TODO:LUKA create helper method for checking if pricingDynamic is null
                    const { label: feePricingTypeLabel } = feePricingDynamicOptions[item.pricingDynamic - 1] || ''

                    return (
                      <Row key={index}>
                        <Column offsetRight responsiveLabel={translate('Fee')}>
                          <Text>{item.name}</Text>
                        </Column>
                        <Column width={30} responsiveLabel={translate('Type')}>
                          <Text>{feePricingTypeLabel}</Text>
                        </Column>
                        <Column width={15} responsiveLabel={translate('Quantity')}>
                          <Text>{item.quantity}</Text>
                        </Column>
                        <Column width={20} alignRight responsiveLabel={translate('Total')}>
                          <Text>
                            {+item.total === 0 ? (
                              translate('Included in rate')
                            ) : (
                              <>
                                {formatPrice(totalPrice)} <SelectedCurrency>{selectedCurrency}</SelectedCurrency>
                              </>
                            )}
                          </Text>
                        </Column>
                      </Row>
                    )
                  })}
                </PriceTable>
              )}

              {touristTaxes && touristTaxes.length > 0 && (
                <PriceTable
                  tableHeader={[
                    { name: 'Tax', offsetRight: true },
                    { name: 'Type', width: 30 },
                    { name: 'Quantity', width: 15 },
                    { name: 'Price', width: 20, alignRight: true }
                  ]}
                  tableFooter={{
                    name: 'Taxes',
                    total: this.getTotalAmount(touristTaxes, currency, currencyConversion),
                    currency: selectedCurrency
                  }}
                >
                  {touristTaxes.map((item, index) => {
                    const { value: totalPrice } = currencyConversion(item.total, currency)
                    // TODO:LUKA add pricing dynamic and pricing type enums when API returns it
                    const { label: taxPricingTypeLabel } = ''

                    return (
                      <Row key={index}>
                        <Column offsetRight responsiveLabel={translate('Fee')}>
                          <Text>{item.name}</Text>
                        </Column>
                        <Column width={30} responsiveLabel={translate('Type')}>
                          <Text>{taxPricingTypeLabel}</Text>
                        </Column>
                        <Column width={15} responsiveLabel={translate('Quantity')}>
                          <Text>{item.quantity}</Text>
                        </Column>
                        <Column width={20} alignRight responsiveLabel={translate('Total')}>
                          <Text>
                            {+item.total === 0 ? (
                              translate('Free')
                            ) : (
                              <>
                                {formatPrice(totalPrice)} <SelectedCurrency>{selectedCurrency}</SelectedCurrency>
                              </>
                            )}
                          </Text>
                        </Column>
                      </Row>
                    )
                  })}
                </PriceTable>
              )}

              <InformationWrapper largePadding>
                <TotalCostWrapper>
                  {this.getTaxes(taxes) && <Information>{this.getTaxes(taxes)}</Information>}
                  <TotalCost>
                    <Information style={{ marginRight: '10px' }}>{translate('Total per unit')}</Information>
                    <Information>
                      {formatPrice(totalPrice)} {selectedCurrency}
                    </Information>
                  </TotalCost>
                </TotalCostWrapper>
              </InformationWrapper>
            </>
          )
        }}
      </CurrenciesContext.Consumer>
    )
  }
}

PricePerDayModal.propTypes = {
  reservation: PropTypes.object
}

export default PricePerDayModal
