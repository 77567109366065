export function formatPrice(price, numberOfDecimals = 2) {
  if (price === null || price === undefined || isNaN(price)) {
    return null
  }

  // Retrieve configuration from the state (or provide default fallbacks)
  const configuration = window?.store?.getState()?.configuration || {}
  const { numberFormat = {}, currency = null } = configuration
  const decimalSeparator = numberFormat.decimalSeparator || '.'
  const thousandSeparator = numberFormat.thousandSeparator || ','

  // Handle VND (round to nearest integer and use no decimals)
  if (currency === 'VND') {
    return Math.round(price).toLocaleString('en-US')
  }

  // Format the price to a fixed number of decimals and return as a string
  const roundedPrice = parseFloat(price).toFixed(numberOfDecimals)

  // Split integer and decimal parts
  const [integerPart, decimalPart] = roundedPrice.split('.')

  // Format integer part with thousand separators
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator)

  // Combine integer and decimal parts with the correct decimal separator
  return `${formattedInteger}${decimalSeparator}${decimalPart}`
}
