import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {
  CartWrapper,
  Details,
  ProceedWrapper,
  Proceed,
  SelectedRooms,
  TotalCostWrapper,
  TotalCost,
  ClearWrapper,
  Clear
} from './style'
import { formatPrice } from 'Rentlio/components/utils/Price/price'
import ArrowRight from 'Rentlio/components/Icons/arrowLongRight'
import Close from 'Rentlio/components/Icons/close'
import { SERVICES_PAGE, PAYMENT_PAGE } from 'Rentlio/utils/routes'
import { LanguageContext } from 'Rentlio/context/LanguageContext'
import { CurrenciesContext } from 'Rentlio/context/CurrenciesContext'

class Cart extends React.PureComponent {
  static contextType = LanguageContext
  render() {
    const {
      totalCost: { accomodationCount, total },
      clearBasket,
      services,
      selectedUnitTypeCurrency
    } = this.props
    const { translate } = this.context
    return (
      <CurrenciesContext.Consumer>
        {({ currencyConversion }) => {
          const { value, currency } = currencyConversion(total, selectedUnitTypeCurrency)
          return (
            <CartWrapper>
              <Details>
                <SelectedRooms>
                  {translate('selected units')}: {accomodationCount}
                </SelectedRooms>
                <TotalCostWrapper>
                  {translate('Total cost')}:
                  <TotalCost>
                    {formatPrice(value)} {currency}
                  </TotalCost>
                </TotalCostWrapper>
                <ClearWrapper onClick={clearBasket}>
                  <Clear>{translate('Clear all')}</Clear>
                  <Close />
                </ClearWrapper>
              </Details>
              <Link to={services.length ? SERVICES_PAGE : PAYMENT_PAGE}>
                <ProceedWrapper>
                  <Proceed>{translate('Proceed')}</Proceed>
                  <ArrowRight />
                </ProceedWrapper>
              </Link>
            </CartWrapper>
          )
        }}
      </CurrenciesContext.Consumer>
    )
  }
}

Cart.propTypes = {
  totalCost: PropTypes.object,
  clearBasket: PropTypes.func,
  services: PropTypes.array,
  selectedUnitTypeCurrency: PropTypes.string
}

export default Cart
