import {
  BE_CREDIT_CARD,
  BE_BANK_TRANSFER,
  BE_MONRI,
  BE_STRIPE,
  BE_M2PAY,
  BE_WSPAY,
  BE_SAFERPAY,
  isSaferpayPaymentPage
} from 'Rentlio/utils/paymentTypesEnum'
import { validateData } from 'Rentlio/helper/validator'
import { cardNumberConstraint } from 'Rentlio/helper/validator/cardNumber'
import { cardCvvNumberConstraint } from 'Rentlio/helper/validator/cardCvv'
import { emailConstraint } from 'Rentlio/helper/validator/email'
import { expirationDateConstraint } from 'Rentlio/helper/validator/expirationDate'
import { maxLengthConstraint } from 'Rentlio/helper/validator/maxLength'
import { notEmptyConstraint } from 'Rentlio/helper/validator/notEmpty'
import { termsOfServiceAcceptedConstraint } from 'Rentlio/helper/validator/termsOfServiceAccepted'

export const getPaymentDetails = (
  selectedPaymentMethod,
  cardholderSameAsGuest,
  firstName,
  lastName,
  cardholderName,
  expirationDate,
  cvv,
  cardNumber,
  isCompany,
  companyName,
  token
) => {
  // If Bank Transfer is selected add only payment method type
  if (selectedPaymentMethod === BE_BANK_TRANSFER) {
    return { type: selectedPaymentMethod }
  }

  const cardholderNamePayload = cardholderSameAsGuest
    ? isCompany
      ? companyName
      : firstName + ' ' + lastName
    : cardholderName

  if (selectedPaymentMethod === BE_CREDIT_CARD) {
    return {
      name: cardholderNamePayload,
      expirationDate,
      cvv,
      cardNumber,
      type: selectedPaymentMethod
    }
  }
  // monri/stripe/m2pay components are checking for errors in monri/stripe/m2pay inputs
  if (
    selectedPaymentMethod === BE_MONRI ||
    selectedPaymentMethod === BE_STRIPE ||
    selectedPaymentMethod === BE_M2PAY ||
    selectedPaymentMethod === BE_WSPAY
  ) {
    return {
      name: cardholderNamePayload,
      type: selectedPaymentMethod
    }
  }
  if (selectedPaymentMethod === BE_SAFERPAY) {
    return {
      type: selectedPaymentMethod
    }
  }

  if (isSaferpayPaymentPage(selectedPaymentMethod)) {
    return {
      type: BE_SAFERPAY,
      aliasToken: token,
      isPaymentPage: true
    }
  }
}

export const getOfferErrors = (selectedPaymentMethod, guestDetails, paymentDetails, termsAccepted) => {
  const commonFields = {
    email: [notEmptyConstraint, val => maxLengthConstraint(val, 150), emailConstraint],
    phone: [notEmptyConstraint, val => maxLengthConstraint(val, 45)],
    country: [notEmptyConstraint],
    termsAccepted: [termsOfServiceAcceptedConstraint],
    estimatedTimeOfArrival: [notEmptyConstraint]
  }

  if (!guestDetails.isCompany) {
    commonFields.firstName = [notEmptyConstraint, val => maxLengthConstraint(val, 20)]
    commonFields.lastName = [notEmptyConstraint, val => maxLengthConstraint(val, 20)]
  } else {
    commonFields.companyName = [notEmptyConstraint, val => maxLengthConstraint(val, 150)]
    commonFields.companyVatId = [notEmptyConstraint]
  }

  if (
    selectedPaymentMethod === BE_BANK_TRANSFER ||
    selectedPaymentMethod === BE_SAFERPAY ||
    isSaferpayPaymentPage(selectedPaymentMethod)
  ) {
    return validateData({ ...guestDetails, ...paymentDetails, termsAccepted }, { ...commonFields })
  }
  if (selectedPaymentMethod === BE_CREDIT_CARD) {
    return validateData(
      { ...guestDetails, ...paymentDetails, termsAccepted },
      {
        ...commonFields,
        name: [notEmptyConstraint],
        expirationDate: [notEmptyConstraint, expirationDateConstraint],
        cvv: [cardCvvNumberConstraint],
        cardNumber: [notEmptyConstraint, cardNumberConstraint]
      }
    )
  }
  // monri/stripe/m2pay components are checking for errors in monri/stripe/m2pay inputs
  if (
    selectedPaymentMethod === BE_MONRI ||
    selectedPaymentMethod === BE_STRIPE ||
    selectedPaymentMethod === BE_M2PAY ||
    selectedPaymentMethod === BE_WSPAY
  ) {
    return validateData(
      { ...guestDetails, ...paymentDetails, termsAccepted },
      {
        ...commonFields,
        address: [notEmptyConstraint, val => maxLengthConstraint(val, 100)],
        city: [notEmptyConstraint, val => maxLengthConstraint(val, 30)],
        postalCode: [notEmptyConstraint, val => maxLengthConstraint(val, 9)],
        name: [notEmptyConstraint]
      }
    )
  }
}
