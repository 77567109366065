import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { CancellationPolicyWrapper, CancellationPolicySegment, BoldText } from '../style'
import {
  getAccommodationCancelationPolicyText,
  getNoShowText,
  getBillingDynamicsText
} from 'Rentlio/helper/getCancellationPolicyText'
import { LanguageContext } from 'Rentlio/context/LanguageContext'
import { CurrenciesContext } from 'Rentlio/context/CurrenciesContext'

const CancellationPolicyFull = ({
  policy,
  totalRatePrice,
  currency,
  paymentMethod,
  servicesCost,
  arrivalDate,
  createdAt
}) => {
  const { translate } = useContext(LanguageContext)
  const { currencyConversion } = useContext(CurrenciesContext)

  if (!policy) {
    return ''
  }

  const termsOfCancellation = getAccommodationCancelationPolicyText(
    translate,
    currencyConversion,
    totalRatePrice,
    currency,
    policy,
    arrivalDate,
    createdAt
  )

  const noShow = getNoShowText(translate, currencyConversion, totalRatePrice, currency, policy)
  const billingDynamics = getBillingDynamicsText(
    translate,
    currencyConversion,
    totalRatePrice + servicesCost,
    currency,
    policy,
    paymentMethod,
    arrivalDate,
    createdAt
  )

  return (
    <CancellationPolicyWrapper>
      <CancellationPolicySegment>
        <BoldText>{translate('Terms of cancellation')}</BoldText>
        {termsOfCancellation}
      </CancellationPolicySegment>

      <CancellationPolicySegment>
        <BoldText>{translate('Billing dynamics')}</BoldText>
        {billingDynamics}
      </CancellationPolicySegment>

      <CancellationPolicySegment>
        <BoldText>{translate('No show')}</BoldText>
        {noShow}
      </CancellationPolicySegment>
      {policy.additionalNote && <CancellationPolicySegment>{policy.additionalNote}</CancellationPolicySegment>}
    </CancellationPolicyWrapper>
  )
}

CancellationPolicyFull.propTypes = {
  policy: PropTypes.object,
  // DO NOT sent already converted values here, send initial values
  totalRatePrice: PropTypes.number,
  currency: PropTypes.string,
  paymentMethod: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  servicesCost: PropTypes.number,
  arrivalDate: PropTypes.number,
  createdAt: PropTypes.number
}

export default CancellationPolicyFull
