import React from 'react'
import PropTypes from 'prop-types'

const GooglePayIcon = ({ width, height }) => (
  <svg width={width} height={height} viewBox='0 0 42 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_1855_2830)'>
      <path
        d='M19.845 10.3405V15.2224H18.27V3.14893H22.365C23.3625 3.14893 24.3075 3.51638 25.0425 4.19879C25.7775 4.82872 26.145 5.7736 26.145 6.77097C26.145 7.76835 25.7775 8.66074 25.0425 9.34315C24.3075 10.0256 23.415 10.393 22.365 10.393L19.845 10.3405ZM19.845 4.61874V8.81822H22.47C23.0475 8.81822 23.625 8.60824 23.9925 8.1883C24.8325 7.4009 24.8325 6.08856 24.045 5.30116L23.9925 5.24866C23.5725 4.82872 23.0475 4.56625 22.47 4.61874H19.845Z'
        fill='#5F6368'
      />
      <path
        d='M29.768 6.71973C30.923 6.71973 31.8155 7.03469 32.498 7.66461C33.1805 8.29453 33.4955 9.13442 33.4955 10.1843V15.2237H32.0255V14.0688H31.973C31.343 15.0137 30.4505 15.4861 29.4005 15.4861C28.508 15.4861 27.7205 15.2237 27.0905 14.6987C26.513 14.1738 26.1455 13.4389 26.1455 12.6515C26.1455 11.8116 26.4605 11.1292 27.0905 10.6042C27.7205 10.0793 28.613 9.86933 29.663 9.86933C30.608 9.86933 31.343 10.0268 31.9205 10.3943V10.0268C31.9205 9.50188 31.7105 8.97694 31.2905 8.66198C30.8705 8.29453 30.3455 8.08456 29.768 8.08456C28.8755 8.08456 28.193 8.45201 27.7205 9.18692L26.3555 8.34702C27.1955 7.24466 28.298 6.71973 29.768 6.71973ZM27.773 12.704C27.773 13.1239 27.983 13.4914 28.298 13.7014C28.6655 13.9638 29.0855 14.1213 29.5055 14.1213C30.1355 14.1213 30.7655 13.8588 31.238 13.3864C31.763 12.914 32.0255 12.3365 32.0255 11.7066C31.553 11.3391 30.8705 11.1292 29.978 11.1292C29.348 11.1292 28.823 11.2867 28.403 11.6016C27.983 11.8641 27.773 12.2315 27.773 12.704Z'
        fill='#5F6368'
      />
      <path
        d='M41.9998 6.97998L36.8023 18.896H35.2273L37.1698 14.749L33.7573 7.03247H35.4373L37.9048 12.9642H37.9573L40.3723 7.03247H41.9998V6.97998Z'
        fill='#5F6368'
      />
      <path
        d='M13.5972 9.29086C13.5972 8.81842 13.5447 8.34598 13.4922 7.87354H6.92969V10.5507H10.6572C10.4997 11.3906 10.0272 12.178 9.29219 12.6504V14.3827H11.5497C12.8622 13.1754 13.5972 11.3906 13.5972 9.29086Z'
        fill='#4285F4'
      />
      <path
        d='M6.93035 16.0597C8.82035 16.0597 10.3954 15.4297 11.5504 14.3799L9.29285 12.6476C8.66285 13.0675 7.87535 13.33 6.93035 13.33C5.14535 13.33 3.57035 12.1227 3.04535 10.4429H0.735352V12.2276C1.94285 14.5899 4.30535 16.0597 6.93035 16.0597Z'
        fill='#34A853'
      />
      <path
        d='M3.04445 10.4449C2.72945 9.60496 2.72945 8.66008 3.04445 7.76769V5.98291H0.734453C-0.263047 7.92517 -0.263047 10.2349 0.734453 12.2296L3.04445 10.4449Z'
        fill='#FBBC04'
      />
      <path
        d='M6.9301 4.93365C7.9276 4.93365 8.8726 5.30111 9.6076 5.98352L11.6026 3.98877C10.3426 2.83392 8.6626 2.1515 6.9826 2.204C4.3576 2.204 1.9426 3.67381 0.787598 6.03602L3.0976 7.82079C3.5701 6.141 5.1451 4.93365 6.9301 4.93365Z'
        fill='#EA4335'
      />
    </g>
    <defs>
      <clipPath id='clip0_1855_2830'>
        <rect width={width} height={height} fill='white' />
      </clipPath>
    </defs>
  </svg>
)

const propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
}

const defaultProps = {
  width: 42,
  height: 20
}

GooglePayIcon.propTypes = propTypes
GooglePayIcon.defaultProps = defaultProps
export default GooglePayIcon
