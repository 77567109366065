import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { PrepaymentMsg } from './style'

import { LanguageContext } from 'Rentlio/context/LanguageContext'
import { formatPrice } from 'Rentlio/components/utils/Price/price'

const BankTransfer = ({ prePaymentDetails, currency }) => {
  const { prepaymentAmount } = prePaymentDetails
  const { translate } = useContext(LanguageContext)

  return (
    <div>
      <PrepaymentMsg>
        {translate('PrepaymentMsg', {
          '%amount%': formatPrice(prepaymentAmount),
          '%currency%': currency
        })}
      </PrepaymentMsg>
    </div>
  )
}

BankTransfer.propTypes = {
  prePaymentDetails: PropTypes.object,
  currency: PropTypes.string
}

export default BankTransfer
