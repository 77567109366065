import moment from 'moment'
import { clearBasket, stopLoading } from 'Rentlio/state/actions/ui'
import { getRoomRequests } from 'Rentlio/state/selectors/payment'
import {
  BOOK_RESERVATION,
  CANCEL_RESERVATION,
  FETCH_AVAILABILITY,
  FETCH_AVAILABILITY_FOR_MONTH,
  FETCH_RESERVATION,
  FETCH_MONRI_CLIENT_SECRET,
  FETCH_STRIPE_CLIENT_SECRET,
  FETCH_M2PAY_CLIENT_SECRET,
  ACCEPT_BOOKING,
  UPDATE_GUEST_REGISTRATION,
  SAFERPAY_ALIAS_INSERT,
  SAFERPAY_PAYMENT_PAGE_INITIALIZE
} from 'Rentlio/state/types'
import { DATE_URL_FORMAT } from 'Rentlio/utils/dateFormatEnum'

export const fetchAvailability = data => (dispatch, getState) => {
  const { request } = getState()
  const from = moment.unix(request.from).format(DATE_URL_FORMAT)
  const to = moment.unix(request.to).format(DATE_URL_FORMAT)

  dispatch({
    type: FETCH_AVAILABILITY,
    meta: {
      async: true,
      path: '/availability',
      method: 'GET',
      body: data
    }
  }).finally(() => {
    setTimeout(() => dispatch(stopLoading()), 500)
  })

  // Dont reset basket if dates havent changed
  if (data.from === from && data.to === to) {
    return
  }

  dispatch(clearBasket())
}

export const fetchAvailabilityForMonth = (unitTypeUuid, year, month) => ({
  type: FETCH_AVAILABILITY_FOR_MONTH,
  meta: {
    async: true,
    path: `/availability/${unitTypeUuid}/${year}/${month}`,
    method: 'GET',
    body: {}
  }
})

export const bookReservation = (guest, payment) => (dispatch, getState) =>
  dispatch({
    type: BOOK_RESERVATION,
    meta: {
      async: true,
      path: '/reservation',
      method: 'POST',
      body: {
        guest,
        payment,
        rooms: getRoomRequests(getState())
      }
    }
  })

export const cancelReservation = id => ({
  type: CANCEL_RESERVATION,
  meta: {
    async: true,
    path: `/reservation/${id}`,
    method: 'DELETE',
    body: {}
  }
})

export const fetchReservation = id => ({
  type: FETCH_RESERVATION,
  meta: {
    async: true,
    path: `/reservation/${id}`,
    method: 'GET',
    body: {}
  }
})

export const getMonriClientSecret = (authenticityToken, bookingEngineId, amount, currency, isWSPay) => {
  if (isWSPay) {
    return {
      type: FETCH_MONRI_CLIENT_SECRET,
      meta: {
        async: true,
        path: '/payment/wspay/client-secret',
        method: 'POST',
        body: {
          shopId: authenticityToken,
          bookingEngineId: bookingEngineId,
          amount: amount,
          currency: currency
        }
      }
    }
  } else {
    return {
      type: FETCH_MONRI_CLIENT_SECRET,
      meta: {
        async: true,
        path: '/payment/monri/client-secret',
        method: 'POST',
        body: {
          authenticityToken: authenticityToken,
          bookingEngineId: bookingEngineId,
          amount: amount,
          currency: currency
        }
      }
    }
  }
}

export const getStripeClientSecret = (publishableApiKey, bookingEngineId, amount, currency, guestDetails) => ({
  type: FETCH_STRIPE_CLIENT_SECRET,
  meta: {
    async: true,
    path: '/payment/stripe/client-secret',
    method: 'POST',
    body: {
      publishableApiKey: publishableApiKey,
      bookingEngineId: bookingEngineId,
      amount: amount,
      currency: currency,
      guestDetails: guestDetails
    }
  }
})

export const getM2PayClientSecret = (merchantId, bookingEngineId) => ({
  type: FETCH_M2PAY_CLIENT_SECRET,
  meta: {
    async: true,
    path: '/payment/m2pay/client-secret',
    method: 'POST',
    body: {
      merchantId: merchantId,
      webBookingEngineId: bookingEngineId
    }
  }
})

export const acceptBookingOffer = (offerUuid, bookingId, data) => ({
  type: ACCEPT_BOOKING,
  meta: {
    async: true,
    path: `/offer/${offerUuid}/${bookingId}/accept`,
    method: 'PUT',
    body: data
  }
})

export const updateGuestRegistration = (guests, uuid) => ({
  type: UPDATE_GUEST_REGISTRATION,
  meta: {
    async: true,
    path: `/reservation/check-in/${uuid}/submit`,
    method: 'PUT',
    body: { guests: guests }
  }
})

export const saferpayAliasInsert = payload => ({
  type: SAFERPAY_ALIAS_INSERT,
  meta: {
    async: true,
    path: '/payment/saferpay/alias/insert',
    method: 'POST',
    body: payload
  }
})

export const saferpayPaymentPageInitialize = payload => ({
  type: SAFERPAY_PAYMENT_PAGE_INITIALIZE,
  meta: {
    async: true,
    path: '/payment/saferpay/payment-page/initialize',
    method: 'POST',
    body: payload
  }
})
